@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.prescription-actions {
  :global {
    .ant-collapse-content-box {
      padding: 0;
      margin: 0;
    }
    .ant-collapse-item {
      display: flex;
      flex-direction: column;
    }
    .ant-collapse-header {
      order: 2;
      display: flex;
      align-items: center;
      padding: 0 !important;
      margin-top: 3.2rem !important;
      margin-bottom: 0 !important;
      background-color: #fff;
      pointer-events: none;

      .ant-collapse-extra {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .right {
          display: flex;
        }

        @include respond-below(sm) {
          flex-direction: column;
          button {
            width: 100%;
            margin-left: 0 !important;
            margin-top: 1.6rem;
          }
          .right {
            flex-direction: column;
          }
        }
      }
    }
    .ant-collapse-content {
      margin: 0 -3.2rem;
      margin-top: 3.2rem;
      padding: 1.6rem 3.2rem;
      background-color: $background-light !important;
    }
  }

  .prescription-details {
    .prescription-details-item {
      color: #828282;
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
      span {
        color: $text;
        font-weight: 500;
      }

      &.instructionsForUse {
        display: flex;
        flex-direction: column;
        margin-bottom: 3.2rem;
        span {
          font-weight: normal;
        }
      }
    }
  }

  :global {
    .prescription-cart-button {
      margin-left: 1.6rem;
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
