.container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 265;
  .group-1 {
    display: flex;
    gap: 4rem;
    align-items: center;
    &-image {
      height: 4.8rem;
      width: 4.8rem;
      svg {
        height: 3.2rem;
        width: 3.2rem;
      }
    }
    &-paragraphs {
      width: 100%;
      display: flex;
      gap: 4rem;
      div:first-child {
        flex: 7;
      }

      div:last-child {
        flex: 3;
      }
    }
  }
  .group-2,
  .group-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    div:nth-child(even) {
      justify-self: end;
      ul {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  ul {
    margin: 0;
    li {
      height: 2.4rem !important;
    }
  }
  .spacer {
    height: 1px;
    width: 100%;
    background-color: #eceff5;
    margin: 4.8rem 0;
  }
}
