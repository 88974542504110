@import "@styles/variables.scss";

.order-summary {
  grid-area: summary;

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    .name {
      color: $text-light;
    }
    .price {
      font-weight: bold;
      color: $primary;
    }
  }

  .divider {
    margin: 2rem -3.2rem;
    height: 1px;
    width: calc(100% + 6.4rem);
    background: #0255a1;
    opacity: 0.2;
    border-radius: 4px;
  }

  .totals {
    .item {
      display: flex;
      justify-content: space-between;
      .name {
        color: $text-light;
      }
      .price {
        font-weight: bold;
        color: $text-light;
      }
      &:last-of-type .price {
        color: $primary;
      }
    }
  }
}
