@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.medio-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.2rem;
  text-align: center;

  &:not(.no-background) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: $background;
  }

  &.prescription-loader {
    background-color: rgba($background, 0.9);
  }

  svg {
    height: 8.6rem !important;
    width: 12.8rem !important;
  }

  .title {
    font-size: 2.4rem;
    margin-top: 3.7rem;
    margin-bottom: 0.8rem;
    color: $text;
    @include respond-below(md) {
      font-size: 2rem;
    }
    @include respond-below(sm) {
      font-size: 1.8rem;
    }
  }
  .subtitle {
    font-size: 2rem;
    color: $text-light;
    @include respond-below(md) {
      font-size: 1.6rem;
    }
    @include respond-below(md) {
      font-size: 1.4rem;
    }
  }
}
