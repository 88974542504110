@import "@styles/variables.scss";

.customer-prescription-count {
  position: relative;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: $tertiary;
  .counter {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: $secondary-light;
    width: 1.6rem;
    height: 1.6rem;
    span {
      width: 16px;
      height: 16px;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: -1px;
      padding-right: 1px;
      color: #fff;
    }
  }
}
