@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.main-navigation {
  height: 100%;
  background: $background;
  border: none !important;
  height: calc(100vh - $header-height);
  max-height: 80rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  svg {
    fill: $background;
  }

  &-badge {
    position: absolute;
    right: 72%;
    top: 0%;
    @include respond-between("sm", "md") {
      right: 0;
    }
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background: $secondary;
    border-radius: 100%;
    color: #fff;
  }

  .ant-layout-sider-collapsed & {
    padding: 2rem 0 !important;

    .ant-menu-item {
      justify-content: center;
      padding: 0 !important;

      @include respond-between("sm", "md") {
        overflow: visible;

        .anticon,
        svg {
          margin-right: 0;
        }
        .ant-menu-title-content {
          height: 100%;
          width: 100%;
          position: absolute;

          overflow: visible;
        }
      }
    }
  }

  .ant-menu-item-divider {
    margin: 2.4rem 0;
    background-color: #0255a2;
    opacity: 0.2;
  }

  .ant-menu-item {
    position: relative;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    display: flex;
    align-items: center;
    height: 5.6rem;
    border-radius: 4rem;
    font-size: 1.8rem;
    color: $text;
    margin: 0 !important;

    &:first-child {
      margin-top: 0;
    }

    // path {
    //   stroke: $tertiary;
    // }

    &-selected {
      color: #fff;
      background: $primary-gradient !important;
      &:after {
        display: none;
      }

      &:hover {
        color: #fff;
      }
      svg {
        fill: transparent;
        path,
        line {
          stroke: #fff;
        }
      }
    }

    .anticon,
    svg {
      margin: 0;
      width: 3.6rem;
      height: 3.6rem;
      margin-right: 1rem;
    }

    span {
      // margin-left: 2.4rem;
      line-height: 3.2rem;
    }
  }
}

@include respond-between("sm", "md") {
  .main-navigation-item-label {
    display: none;
  }
}

// Removes scroll on small screens
.ant-menu {
  height: 100%;
}
