.item-cart-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  // This fixes a bug in rc-tooltip: https://github.com/react-component/tooltip/issues/18#issuecomment-650864750
  .tooltip-content {
    cursor: not-allowed;
    button {
      pointer-events: none !important;
    }
  }

  .plus,
  .minus {
    min-width: 4.8rem !important;
    min-height: 4.8rem !important;
    padding: 0 !important;
    :global {
      .Streamline_Icon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .single {
    // margin-left: auto;
    svg {
      transform: scale3d(0.75, 0.75, 0.75);
    }
    * {
      stroke: #fff;
    }
  }

  .quantity {
    margin-left: 2rem;
    margin-right: 2rem; 
  }
}
