@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.otc-products-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: $text-light;
  height: 100%;

  .link {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      opacity: 0;
      transition: opacity 0.2s;
    }
    &:hover {
      .title {
        color: $primary;
      }
      &:after {
        opacity: 0.25;
      }
    }
  }

  .image {
    width: 18rem;
    height: 18rem !important;
    margin: 2.4rem auto;
    margin-top: 0;
    object-fit: contain;
  }
  .title {
    padding-bottom: 1.6rem;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 2rem;
    color: $text;
  }
  .info {
    padding-bottom: 1.6rem;
  }
  .form {
    padding-bottom: 1.6rem;
  }
  .price {
    padding-bottom: 2.4rem;
    span {
      color: $primary;
      font-weight: bold;
    }
  }
  .buttons {
    min-height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}