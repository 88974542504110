@import "@styles/responsive-helpers.scss";

.order-details-content-skeletons {
  display: grid;
  grid-template-areas:
    "general summary"
    "address address"
    "notes notes";
  grid-gap: 2rem;

  @include respond-below(sm) {
    grid-template-areas:
      "general"
      "summary"
      "address"
      "notes";
  }

  .general {
    grid-area: general;
  }
  .summary {
    grid-area: summary;
  }
  .address {
    grid-area: address;
  }
  .notes {
    grid-area: notes;
  }

  .general,
  .summary {
    flex-direction: row !important;
    justify-content: space-between;
    :global {
      .ant-skeleton {
        width: 35% !important;
        &:first-of-type {
          li {
            &:nth-of-type(2) {
              width: 60%;
            }
            &:nth-of-type(4) {
              width: 90%;
            }
          }
        }
        &:last-of-type {
          width: 40% !important;
          li {
            margin-left: auto;
            &:nth-of-type(2) {
              width: 80%;
            }
            &:nth-of-type(4) {
              width: 70%;
            }
          }
        }
      }
    }
  }

  .address {
    :global {
      .ant-skeleton {
        width: 55% !important;
        &:first-of-type {
          width: 65% !important;
        }
        &:last-of-type {
          margin-left: 3.2rem;
          @include respond-below(sm) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .thank-you-page-skeletons & {
    display: flex;
    flex-direction: column;
  }
}
