@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.generic-drug {
  width: 100%;
  border: solid 1px #fff;
  padding: 2rem 4rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  text-align: left;
  background-color: #fff;
  color: $text;
  box-shadow: 0px 10px 20px rgba(116, 116, 116, 0.06);
  @include respond-below(sm) {
    padding: 0.8rem 1.6rem;
  }

  &:hover {
    background-color: #fff;
    border-color: $primary;
    color: $primary;
    box-shadow: 0px 6px 12px rgba(65, 156, 175, 0.24);
  }

  &:focus {
    outline: none;
  }

  .title {
    font-size: 1.8rem;
    font-weight: bold;
    @include respond-below(sm) {
      font-size: 1.6rem;
    }
  }

  .price {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    @include respond-below(sm) {
      font-size: 1.4rem;
    }

    .label {
      color: $text-light;
    }

    .amount {
      color: $primary;
      font-weight: bold;
    }
  }

  &.selected-drug {
    border: solid 1px $primary;
    background: $primary;
    box-shadow: 0px 6px 12px rgba(65, 156, 175, 0.24);

    .title,
    .label,
    .amount {
      color: #fff;
    }
  }
}
