@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.grid {
  display: grid;
  grid-template: 
              "image information"
              "tabs tabs";

  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-left: 4.8rem;

  @include respond-below("md") {
    padding-left: unset;

    grid-template: 
            "image"
            "information"
            "tabs";
  }

  .product {
    
    &__image {
      grid-area: image;

      @include respond-below("md") {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.4rem;
      }

      .image-skeleton {
        max-width: 80%;
        min-width: 36rem;
        min-height: 26rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 10px 30px rgba(49, 151, 196, 0.1);
      }
    }

    &__info {
      grid-area: information;

      &__title {
        font-weight: 700;
        font-size: 3.2rem;
        letter-spacing: -0.02em;
        color: #585858;
      }

      &__long {
        font-size: 1.8rem;
      }
      
      &__tags {
        display: grid;
        grid-template-columns: repeat(4, auto);
        gap: 1rem;

        @include respond-below("md") {
          grid-template-columns: repeat(2, auto);
        }
        margin-bottom: 2.4rem;
      }
      &__links {
        display: flex;
        
      }

      &__description {
        min-height: 20rem;
        margin-bottom: 2.4rem;
      }

      &__price__container {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include respond-below("md") {
          grid-template-columns: repeat(1, auto);
          gap: 1rem;
        }
        margin-bottom: 2.4rem;
        align-items: center;

        &__price {
          color: $primary;
          font-size: 2.4rem;
          font-weight: 700;
          letter-spacing: -0.02em;
          text-align: right;

        }
      }
    }

    &__tabs {
      grid-area: tabs;

      :global {
        .ant-tabs-ink-bar {
          background: $primary;
        }
        .ant-tabs-nav {
          &::before {
            border: 1px solid #e1e1e6;
          }
        }
        .ant-tabs-tab-btn {
          color: $text;
        }   
        .ant-tabs-tab.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 700;
            color: $primary;
          }  
        }
      }

      &__item__subitem {
        margin-bottom: 1.6rem;
        .label {
          font-weight: 700;
          
        }
      }
     
      @include respond-below(sm) {
        min-height: 20rem;
      }
    }
  }
}