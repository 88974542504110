.medio-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    height: 12.8rem !important;
    width: 12.8rem !important;
  }
  .title {
    text-align: center;
  }
}
