@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.otc-skeleton {
  .top {
    :global {
      .ant-skeleton-title {
        height: 3rem;
        margin: 0 auto !important;
        width: 75% !important;
      }
      .ant-skeleton-paragraph {
        height: 2.4rem;
        display: flex;
        justify-content: space-evenly;
        margin: 2.4rem 0 !important;
        li {
          height: 2.4rem;
          width: 25% !important;
          margin: 0 !important;
        }
      }
    }
  }

  .center,
  .bottom {
    margin-bottom: 2.4rem;
    :global {
      .ant-skeleton-paragraph {
        height: 2.4rem;
        display: flex;
        justify-content: center;
        margin: 0 auto !important;
        li {
          height: 2.4rem;
          width: 65% !important;
          margin: 0 !important;
        }
      }
    }
  }
  .bottom {
    :global {
      .ant-skeleton-paragraph {
        li {
          width: 50% !important;
        }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    :global {
      .ant-skeleton-button {
        border-radius: 50px;
        height: 4.8rem;
      }
    }
    .cart {
      margin-left: auto;
      width: 4.8rem !important;
      border-radius: 100% !important;
    }
  }
}
