@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.prescription-labels {
  margin-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;

  @include respond-below(sm) {
    margin-top: 0.8rem;
  }
}
