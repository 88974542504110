.medio-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    height: 128px !important;
    width: 128px !important;
  }
}
