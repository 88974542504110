@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.si-status-item-skeleton {
  display: flex !important;
  :global {
    .ant-skeleton-paragraph {
      display: grid;
      align-items: center;
      grid-template-areas: "step cost percentage";
      grid-template-columns: 80px 3fr 2fr;
      grid-gap: 1rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;

      li {
        margin: 0 !important;
        &:nth-of-type(1) {
          grid-area: step;
          max-width: 2.4rem;
        }
        &:nth-of-type(2) {
          grid-area: cost;
          max-width: 17.2rem;
        }
        &:nth-of-type(3) {
          grid-area: percentage;
          max-width: 4.8rem;
        }
      }
    }
  }

  .mobile-only {
    display: none;
  }

  @include respond-below(sm) {
    justify-content: space-between;
    :global {
      .ant-skeleton {
        width: calc(50% - 1.6rem);
      }
      .ant-skeleton-paragraph {
        grid-template-areas:
          "step"
          "cost"
          "percentage";
        grid-template-columns: 1fr;
        li {
          margin-bottom: 0.8rem !important;
          &:last-of-type {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    li {
      &:first-of-type {
        max-width: 4.8rem;
      }
      &:nth-of-type(2) {
        max-width: 28rem;
      }
      &:nth-of-type(3) {
        max-width: 19.2rem;
      }
    }
    .mobile-only {
      display: flex;
      :global {
        .ant-skeleton-paragraph {
          justify-items: end;
        }
        li {
          &:first-of-type {
            max-width: 2.4rem;
          }
          &:nth-of-type(2) {
            max-width: 17.2rem;
          }
          &:nth-of-type(3) {
            max-width: 4.8rem;
          }
        }
      }
    }
  }
}
