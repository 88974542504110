@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.image {
  grid-area: image;
  max-width: 36rem;

  @include respond-below("md") {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
    max-width: 100%;
  }

  :global {
    .slick-slide div {
     

      img{
        border-radius: 20px;
        
        
        background-color: white;  
      }
    }
  }
  
    
  
  
  :global {
    li.slick-active {
      button {
        background: $primary !important;
      }
    }

    li {
      &:not(.slick-active) {
        background-color: #fff;
      }
    }
  }
  
}