.si-status-text {
  :global {
    .ant-skeleton {
      display: inline-flex;
      .ant-skeleton-title {
        margin: 0;
      }

      &:nth-of-type(1) {
        width: 10.4rem;
      }
      &:nth-of-type(2) {
        width: 10.4rem;
      }
      &:nth-of-type(3) {
        width: 4.8rem;
      }
      &:nth-of-type(4) {
        width: 2.4rem;
      }
    }
  }
}
