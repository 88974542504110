@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.prescription-customer-skeleton {
  position: relative;
  background: #fff !important;
  :global {
    .ant-skeleton-header {
      @include respond-below(sm) {
        padding-right: 0 !important;
      }
    }

    .ant-skeleton-avatar {
      width: 6rem;
      height: 6rem;
    }
    .ant-skeleton-title {
      margin: 0 !important;
      @include respond-below(sm) {
        margin: 0 auto !important;
        margin-top: 1.9rem !important;
      }
    }
    .ant-skeleton-paragraph {
      margin-top: 1.6rem !important;
      margin-bottom: 0;
      max-width: 16rem;
      @include respond-below(sm) {
        margin: 1.6rem auto !important;
        max-width: 20rem;
      }
      li:last-of-type {
        position: absolute;
        right: 2.4rem;
        top: calc(50% - 2.4rem);
        max-width: 7.2rem;
        @include respond-below(sm) {
          display: none;
        }
      }
    }
  }
}
