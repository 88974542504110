@import "./fonts.css";
@import "./variables.scss";
@import "./root.scss";
@import "./responsive-helpers.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

$font-size-percentage: 62.5%;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline-color: $tertiary !important;
}

::selection {
  color: white;
  background: $tertiary;
}

html {
  box-sizing: border-box;
  font-size: 62.5% !important; // 10px / 16px = 62.5% -> 1rem = 10px
  // every 56px we reduce font-size by 0.5%, 20 times
  // so we end at 320px with 52.5%
  @for $i from 1 through 20 {
    $font-size-percentage: $font-size-percentage - 0.5%;
    @media screen and (max-width: 1440px - ($i * 56px)) {
      font-size: $font-size-percentage !important;
    }
  }
  margin: 0;
}

header.site-header {
  display: none; // WP Header
}

body {
  margin: 0;
  font-family: "Apercu Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 1.6rem !important;
  height: calc(100vh - 100px) !important;

  &.medio-is-loading {
    overflow: hidden !important;
  }
}

#medio-root,
.medio-modal-root {
  .Medio {
    display: grid;
    grid-template-areas:
      "header header header"
      ". content .";
    grid-template-columns: 1fr $max-width 1fr;
    grid-template-rows: $header-height 1fr;
    min-height: calc(100vh - 100px);
  }
  .content {
    grid-area: content;
    max-width: 100vw;
    width: 100%;
    display: grid;
    grid-template-areas: "sider main";
    grid-template-columns: $sider-width calc(100% - #{$sider-width});
    min-height: calc(100vh - 100px);
    flex-direction: column !important;
    background: $background;

    &.isCheckout {
      grid-template-areas: "main";
      grid-template-columns: 1fr;
    }

    &-main {
      grid-area: main;
      width: 100% !important;
      max-width: 100vw;
      min-height: calc(100vh - 8.8rem);
      padding: 2rem;

      @include respond-below(sm) {
        padding-bottom: $mobile-nav-height-collapsed + 3.2rem;
      }
    }

    @include respond-above(lg) {
      grid-template-columns: $sider-width calc(#{$max-width} - #{$sider-width});
    }
    @include respond-below(md) {
      grid-template-columns: $sider-width-collapsed calc(
          100% - #{$sider-width-collapsed}
        );
    }
    @include respond-below(sm) {
      grid-template-areas:
        "header"
        "main";
      grid-template-columns: 1fr;
    }
  }

  .ant-form-item-label {
    padding: 0 !important;
  }

  .medio-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - #{$header-height} - (#{$header-height} / 2));

    @include respond-below(md) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: $mobile-nav-height-collapsed;
      left: 0;
    }
  }

  .medio-content-box {
    display: flex;
    flex-direction: column;
    padding: 3.2rem;
    border: 1px solid $border-light;
    border-radius: 4px;
    background-color: #fff;
    transition: ease 0.3s;

    &:hover {
      box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
    }

    &.rounded {
      padding: 2.4rem 3.2rem;
      border-radius: 10px;
    }
    // TODO: Maybe add media queries for padding
  }

  .ant-layout-footer,
  .ant-layout-header {
    padding: 0;
    margin: 0;
    height: auto;
    color: white;
    background: $background;
  }

  .ant-layout {
    overflow-x: hidden;
    background: $background;
    color: $text;

    @include respond-below("md") {
      overflow-x: visible;
    }
  }

  .ant-layout-sider {
    position: fixed;
    top: $header-height; // WP Header
    left: calc((100vw - 1200px) / 2);
    height: calc(100vh - #{$header-height});
    max-height: calc(100vh - #{$header-height} - 4rem);
    overflow-y: auto;
    padding: 0 2rem;

    color: white;
    background: $background;

    @include respond-below(lg) {
      left: 0 !important;
    }

    @include respond-below(sm) {
      display: none !important;
    }
  }

  .ant-input-group {
    margin-bottom: 3.2rem;
  }

  .ant-form-item {
    margin-bottom: 0;

    &-label label {
      font-size: 1.6rem;
      color: $text-light;
      padding-bottom: 0.8rem;
    }

    &-has-error {
      input {
        border-color: $secondary !important;
      }
    }
  }

  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    padding: 1rem 2.4rem;
    background: #ffffff;
    border: 1px solid $border;
    border-radius: 6px;
    color: $text;
    font-size: 1.6rem;
    &:hover,
    &:focus,
    &:active {
      border-color: $primary;
    }
  }

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.4rem 3.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    border: none;
    border-radius: 24px;
    pointer-events: all;
    box-shadow: 0 10px 30px rgba(49, 151, 196, 0.1);
    transition: box-shadow 0.5s ease, transform 0.3s ease;

    &:not(.disabled):hover {
      box-shadow: 0 8px 30px rgb(49 151 196 / 60%) !important;
      transform: translate3d(0, -2px, 0);
    }

    &.disabled {
      opacity: 0.25;
    }

    &.right {
      margin-left: auto;
    }

    &.primary {
      background-color: $primary;
      color: #fff;
    }

    &.primary-gradient {
      background: $primary-gradient;
      color: #fff;
    }

    &.light {
      background: $background-light;
      color: $primary;
      box-shadow: none;
    }
  }
}

// TODO: Move to specific file
.status-page {
  strong {
    color: $primary;
  }
}

// MESSAGE
.ant-message-notice-content {
  padding: 0.8rem 1.6rem;
  border: 1px solid $border-light;
  border-radius: 4px;

  .anticon.anticon-close-circle {
    color: $warning-light;
  }

  .ant-message-custom-content {
    > span {
      font-size: 1.6rem !important;
    }
    .anticon {
      font-size: 1.8rem !important;
      margin-right: 0.8rem;
    }
  }
}

// TOOLTIP
.ant-tooltip-inner {
  background-color: #fff;
  color: $text;
  border: solid 1px $border-light;
}
.ant-tooltip-arrow-content {
  background-color: #ccc;
}

// BADGE
.ant-scroll-number.ant-badge-count {
  background-color: $primary;
}

// STEPS
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $primary;
}

.ant-steps-item:hover:not(.ant-steps-item-active) {
  .ant-steps-icon,
  .ant-steps-item-icon,
  .ant-steps-item-title {
    color: $primary !important;
  }
  .ant-steps-item-icon {
    border-color: $primary !important;
  }
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $primary;
}

.ant-steps-item-process .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $primary;
}

// Checkbox
.ant-checkbox-inner {
  width: 2.4rem;
  height: 2.4rem;
  &:after {
    top: 40%;
    width: 35%;
    height: 70%;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}
.ant-checkbox-checked::after {
  border-color: $primary;
}

// Collapsible
.ant-collapse-item {
  position: relative;

  &.medio-content-box {
    margin-bottom: 1.6rem;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  &-active {
    .ant-collapse-header {
      margin-bottom: 3.2rem;
    }
  }
  .ant-collapse-header {
    position: relative;
    padding: 0 !important;
    padding-right: 2.4rem !important;
    font-size: 2rem;
    font-weight: 500;
    color: $text;
    &:after {
      position: absolute;
      top: -2.4rem;
      right: -3.2rem;
      bottom: -2.4rem;
      left: -3.2rem;
      height: calc(100% + 2.4rem + 2.4rem);
      width: calc(100% + 2.4rem + 2.4rem);
    }

    @include respond-below(sm) {
      font-size: 1.6rem;
    }

    .ant-collapse-arrow {
      top: calc(50% - (1.6rem / 2)) !important;
      right: 0 !important;
      padding: 0 !important;
      font-size: 1.6rem !important;
      color: $text !important;
    }
  }

  &.has-icon {
    .ant-collapse-header {
      padding-left: 3.6rem !important;
    }
  }

  .collapsible-icon {
    float: none;
    position: absolute;
    left: 0;
  }
}

.Streamline_Icon--spin {
  animation-iteration-count: infinite !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}

.search-container {
  grid-area: search;
}

.rapyd-notice {
  padding-left: 2rem;
  padding-bottom: 0.8rem;
}

.rapyd-flags {
  display: flex;
  justify-content: flex-end;
  .flag {
    cursor: pointer;
    width: 32px;
    height: 24px;
    margin-left: 0.8rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    border-radius: 2px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }

    &.en {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjAgMzAiIGhlaWdodD0iNjAwIj4NCjxkZWZzPg0KPGNsaXBQYXRoIGlkPSJ0Ij4NCjxwYXRoIGQ9Im0zMCwxNWgzMHYxNXp2MTVoLTMwemgtMzB2LTE1enYtMTVoMzB6Ii8+DQo8L2NsaXBQYXRoPg0KPC9kZWZzPg0KPHBhdGggZmlsbD0iIzAwMjQ3ZCIgZD0ibTAsMHYzMGg2MHYtMzB6Ii8+DQo8cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iNiIgZD0ibTAsMGw2MCwzMG0wLTMwbC02MCwzMCIvPg0KPHBhdGggc3Ryb2tlPSIjY2YxNDJiIiBzdHJva2Utd2lkdGg9IjQiIGQ9Im0wLDBsNjAsMzBtMC0zMGwtNjAsMzAiIGNsaXAtcGF0aD0idXJsKCN0KSIvPg0KPHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEwIiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjxwYXRoIHN0cm9rZT0iI2NmMTQyYiIgc3Ryb2tlLXdpZHRoPSI2IiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjwvc3ZnPg0K");
    }
    &.is {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDcyMCI+DQo8ZyB0cmFuc2Zvcm09InNjYWxlKDQwKSI+DQo8cGF0aCBmaWxsPSIjMDAzODk3IiBkPSJtMCwwaDI1djE4aC0yNXoiLz4NCjxnIGZpbGw9IiNmZmYiPg0KPHBhdGggZD0ibTAsN2gyNXY0aC0yNXoiLz4NCjxwYXRoIGQ9Im03LDBoNHYxOGgtNHoiLz4NCjwvZz4NCjxnIGZpbGw9IiNkNzI4MjgiPg0KPHBhdGggZD0ibTAsOGgyNXYyaC0yNXoiLz4NCjxwYXRoIGQ9Im04LDBoMnYxOGgtMnoiLz4NCjwvZz4NCjwvZz4NCjwvc3ZnPg0K");
    }
  }
}

.ke-product-card {
  color: $text-light;

  &__link {
    &:hover {
      .ke-product-card__link-title {
        color: $primary;
      }
    }
  }

  &__link-title {
    color: $text;
  }

  &__price {
    span {
      color: $primary;
    }
  }
}

.ke-product-carousel {
  &.embla {
    position: relative;
  }

  .embla__viewport {
    overflow: hidden;
    &.is-draggable {
      cursor: move;
      cursor: grab;
    }
    &.is-dragging {
      cursor: grabbing;
    }
  }

  .embla__container {
    display: flex;
    gap: 1.6rem;
    will-change: transform;
  }

  .embla__slide {
    position: relative;
    flex: 0 0 calc(100% / 3 - (1.6rem / 3 * 2));

    @include respond-below(md) {
      flex: 0 0 calc(50% - 1.6rem);
    }
  }

  .embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    border: 0;
    width: 3.2rem;
    height: 3.2rem;
    justify-content: center;
    align-items: center;
    fill: $primary;
    padding: 0.8rem;
    border-radius: 8px;
    background: #fff;
  }

  .embla__buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: 0.8rem;
  }

  .embla__button--prev {
    padding-left: 0.6rem;
  }

  .embla__button--next {
    padding-right: 0.6rem;
  }

  .embla__button:disabled {
    cursor: default;
    opacity: 0.4;
  }

  .embla__button__svg {
    width: 100%;
    height: 100%;
  }

  .embla__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    padding-top: 0.8rem;
  }

  .embla__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    display: flex;
    align-items: center;
  }

  .embla__dot:after {
    background-color: #efefef;
    width: 100%;
    height: 6px;
    border-radius: 8px;
    content: "";
  }

  .embla__dot.is-selected:after {
    background: $primary;
    opacity: 1;
  }

  .product-card__link-title {
    min-height: 7.8rem;
  }
}

@import "./mobileNavigation.scss";
@import "./navigation.scss";
