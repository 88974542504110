@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.page-header {
  position: fixed;
  top: 0; // WP Header
  left: 0;
  right: 0;
  z-index: 250;
  border-bottom: solid 1px transparent;

  .scrolled {
    border-color: $border;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $max-width;
    padding: 3.2rem 2rem;
    margin: 0 auto;

    > a {
      display: flex;
    }
  }

  .user {
    height: 24px;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    color: $text-light;
    font-weight: 700;
    .name {
      color: $text;
      padding-left: 6px;
    }
  }

  .site-logo {
    svg {
      width: 140px;
      height: 24px;
    }
  }
}
