@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.field-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  border-radius: 10px !important;
  .span-2 {
    grid-column: span 2;
  }
  :global {
    .ant-skeleton {
      li {
        height: 4rem;
      }
      ul {
        margin: 0 !important;
      }
      h3 {
        margin: 0 0 1rem 0 !important;
        width: 8rem !important;
      }
    }
  }
}
.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;

  div:nth-child(2) {
    justify-self: end;
  }
  :global {
    .ant-skeleton-button {
      width: 15rem;
    }
  }
}

@include respond-below(sm) {
  .field-container {
    div {
      grid-column: span 2 !important;
    }
  }
}
