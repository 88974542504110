@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.cart-prescriptions {
  .main-title {
    display: none;
    margin: 0;
    padding-bottom: 1.6rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: $text;
    @include respond-below(sm) {
      display: block;
    }
  }
  .header {
    display: grid;
    align-items: center;
    grid-template-areas: "image name fullprice siamount discount price action";
    grid-template-columns: 7.2rem 3fr repeat(4, minmax(90px, 1fr)) 20px;
    grid-gap: 1rem;
    padding: 1.6rem 3.2rem;
    @include respond-below(sm) {
      display: none;
    }
    .image {
      font-weight: bold;
    }
  }

  .items {
    .cart-item {
      display: grid;
      align-items: center;
      grid-template-areas: "image name fullprice siamount discount price action";
      grid-template-columns: 7.2rem 3fr repeat(4, minmax(90px, 1fr)) 20px;
      grid-gap: 1rem;
      padding: 1.6rem 3.2rem;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 1.6rem;
      transition: ease 0.3s;

      &:hover {
        box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
      }

      > div {
        > span {
          display: none;
        }
      }

      .image {
        margin-left: auto;
        img {
          width: 6.4rem;
          height: 6.4rem;
        }
      }

      .name {
        grid-area: name;
        .name-title {
          font-weight: 700;
        }
        .name-description {
          color: $text-light;
        }
      }
      .description {
        display: none;
      }
      .fullprice {
        grid-area: fullprice;
        font-weight: 700;
        color: $primary;
      }
      .siamount {
        grid-area: siamount;
        font-weight: 700;
        color: $primary;
      }
      .discount {
        grid-area: discount;
        font-weight: 700;
        color: $primary;
      }
      .price {
        grid-area: price;
        font-weight: 700;
        color: $primary;
      }
      .action {
        grid-area: action;
        text-align: right;
        button {
          border: none;
          font-size: 1.8rem;
          background: none;
          padding: 0;
          color: $text-light;
          &:hover,
          &:focus {
            cursor: pointer;
            background: none;
            color: $primary;
          }
        }
      }

      .label {
        :global {
          .ant-skeleton-paragraph {
            margin: 0 !important;
            width: 20rem;
            height: 1.6rem;
            li {
              margin: 0 !important;
              margin-left: auto !important;
            }
          }
        }
      }

      @include respond-below(sm) {
        grid-template-areas:
          "image"
          "name"
          "description"
          "fullprice"
          "siamount"
          "discount"
          "price"
          "action";
        grid-template-columns: 1fr;
        text-align: right;

        .name {
          &-description {
            display: none;
          }
        }
        .description {
          grid-area: description;
          display: flex;
        }
        > div {
          display: flex;
          justify-content: space-between;
          > span {
            display: block;
            font-weight: normal;
            color: $text-light;
          }
        }
      }
    }
  }
}
