@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.main-mobile-navigation {
  @include respond-above(sm) {
    display: none !important;
  }
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: $mobile-nav-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  border-radius: 44px 44px 0 0;
  box-shadow: 0px -4px 40px 20px rgba(0, 0, 0, 0.04);
  background: #fff;
  z-index: 1000;
  transform: translate3d(
    0,
    $mobile-nav-height - $mobile-nav-height-collapsed,
    0
  );
  transition: transform 0.3s ease;

  body.medio-is-loading & {
    transform: translate3d(0, $mobile-nav-height + 0.4rem, 0);
  }

  &-outside {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 50;
  }

  &-top {
    margin-top: 1.6rem;
  }

  &-top,
  &-bottom {
    display: flex;
    justify-content: space-between;
    border: none !important;
  }

  &-bottom {
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 0.3s;
    margin-top: 3.2rem;
    li:nth-of-type(1),
    li:nth-of-type(2),
    li:nth-of-type(3) {
      margin-bottom: 3.2rem !important;
    }
  }

  &-badge {
    position: absolute;
    right: calc(50% - 3.2rem);
    top: -10%;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background: $secondary;
    border-radius: 100%;
    color: #fff;
  }

  .ant-menu-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto !important;
    width: 33%;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 1.4rem;
    color: $text-light;
    background: transparent !important;
    overflow: visible;

    .ant-menu-title-content {
      margin-left: 0;
    }

    &.mobile-expand {
      svg {
        transform: rotate(0deg);
        transition: transform 0.2s;
      }
    }

    .anticon {
      display: flex;
      padding: 0;
      margin: 0;
      border-radius: 100%;
      svg {
        margin: 0 auto;
        width: 3.8rem;
        height: 3.8rem;
        fill: none;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &-selected {
      .main-mobile-navigation-badge {
        top: -1.2rem;
      }
      .anticon {
        position: relative;
        transform: translate3d(0, -1.6rem, 0);
        color: #fff;
        svg {
          position: relative;
          z-index: 10;
          margin-bottom: auto;
        }
        path,
        line {
          stroke: #fff;
        }
        &:after {
          content: "";
          position: absolute;
          top: -1rem;
          left: -1.1rem;
          height: 6rem;
          width: 6rem;
          background: $primary-gradient !important;
          z-index: 9;
          border-radius: 100%;
          box-shadow: 0px 10px 20px rgba(64, 119, 154, 0.2);
        }
      }
      &:after {
        display: none;
      }
    }
  }

  &.isToggled {
    transform: translate3d(0, 0, 0);
    .main-mobile-navigation-bottom {
      opacity: 1;
      display: flex;
    }
    .mobile-expand {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
}
