@font-face {
  font-family: "Apercu Pro";
  src: url("/fonts/ApercuPro-Italic.woff2") format("woff2"),
    url("/fonts/ApercuPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("/fonts/ApercuPro-BoldItalic.woff2") format("woff2"),
    url("/fonts/ApercuPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("/fonts/ApercuPro.woff2") format("woff2"),
    url("/fonts/ApercuPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("/fonts/ApercuPro-Medium.woff2") format("woff2"),
    url("/fonts/ApercuPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("/fonts/ApercuPro-Bold.woff2") format("woff2"),
    url("/fonts/ApercuPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Apercu Pro";
  src: url("/fonts/ApercuPro-MediumItalic.woff2") format("woff2"),
    url("/fonts/ApercuPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
