@import "@styles/variables.scss";

.line-item {
  display: flex;
  gap: 1.6rem;
  &-cart {
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    path {
      stroke: $tertiary;
    }
  }
  &-check {
    stroke: $tertiary !important;
  }

  &-link {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-name,
    &-price {
      color: $primary;
    }
    &-price {
      font-weight: bold;
    }
  }
}
