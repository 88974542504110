@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.help-skeleton {
  display: flex !important;
  margin-bottom: 1.6rem;
  :global {
    .ant-skeleton-content {
      display: flex;
      justify-content: space-between;
    }
    .ant-skeleton-title {
      width: 60% !important;
      height: 2.4rem;
      margin: 0.25rem 0;
    }
    .ant-skeleton-paragraph {
      margin-top: 0.6rem !important;
      width: 2.4rem;
      height: 1.6rem;
      li {
        margin: 0 !important;
        margin-left: auto !important;
      }
    }
  }
}
