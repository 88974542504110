@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.cart-item {
  display: grid;
  align-items: center;
  grid-template-areas: "image name fullprice quantity price action";
  grid-template-columns: 6.4rem 3fr repeat(3, minmax(90px, 1fr)) 20px;
  grid-gap: 1.6rem;
  padding: 1.6rem 3.2rem;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 1.6rem;
  transition: ease 0.3s;

  &:hover {
    box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
  }

  > div {
    > span {
      display: none;
    }
  }

  a {
    color: $text;
    &:hover {
      color: $primary;
    }
  }

  .image {
    img {
      width: 6.4rem;
      height: 6.4rem;
      object-fit: contain;
    }
  }

  .name {
    grid-area: name;
    .name-title {
      font-weight: 700;
    }
    .name-description {
      color: $text-light;
    }
  }
  .description {
    display: none;
  }
  .fullprice {
    grid-area: fullprice;
    font-weight: 700;
    color: $primary;
  }
  .discount {
    grid-area: discount;
    font-weight: 700;
    color: $primary;
  }
  .price {
    grid-area: price;
    font-weight: 700;
    color: $primary;
  }
  .action {
    grid-area: action;
    text-align: right;
    button {
      border: none;
      font-size: 1.8rem;
      padding: 0;
      background: none;
      color: $text-light;
      height: 2.4rem;
      width: 2.4rem;
      i {
        height: 2.4rem;
        width: 2.4rem;
      }
      &:hover,
      &:focus {
        cursor: pointer;
        background: none;
        color: $primary;
      }
    }
  }
  
  .quantity {
    grid-area: quantity;

    input {
      width: 6.4rem;
      padding: 1rem !important;
      text-align: center;

    }

  }
  @include respond-below(sm) {
    grid-template-areas:
      "image"
      "name"
      "description"
      "fullprice"
      "discount"
      "price"
      "action";
    grid-template-columns: 1fr;
    text-align: right;
    max-width: 100%;

    .image {
      margin: auto;
      img {
        width: 18rem;
        height: 18rem;
      }
    }

    .name {
      &-description {
        display: none;
      }
    }
    .description {
      grid-area: description;
      display: flex;
    }
    > div {
      display: flex;
      justify-content: space-between;
      > span {
        display: block;
        font-weight: normal;
        color: $text-light;
      }
    }
  }
}
