@import "@styles/variables.scss";

.page-title {
  display: flex;
  justify-content: space-between;
  font-size: 2.2rem;
  margin-bottom: 3.2rem;

  .title {
    font-weight: bold;
  }

  .subtitle {
    color: $text-light;
  }

  grid-area: pagetitle;
}
