@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.orders-header {
  display: grid;
  align-items: center;
  grid-template-areas: "id date shipping-method total status";
  grid-template-columns:
    minmax(50px, 1fr) minmax(100px, 1fr) 1fr minmax(80px, 1fr)
    105px;
  grid-gap: 1rem;
  padding: 1.6rem 3.2rem;
  @include respond-below(sm) {
    display: none;
  }
}
