@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  .pages {
    display: flex;
    align-items: center;

    input {
      width: 5rem !important;
      margin: 0 1rem;
      padding: 1rem 0rem !important;
      text-align: center;
    }
  }
  p {
    margin: 0;
  }
}