@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.cart-items {
  .title {
    display: none;
    margin: 0;
    padding-top: 3.6rem;
    padding-bottom: 1.6rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: $text;
    @include respond-below(sm) {
      display: block;
    }
  }
  .header {
    display: grid;
    align-items: center;
    grid-template-areas: "image name fullprice quantity price action";
    grid-template-columns: 6.4rem 3fr repeat(3, minmax(90px, 1fr)) 20px;
    grid-gap: 1.6rem;
    padding: 1.6rem 3.2rem;
    @include respond-below(sm) {
      display: none;
    }
    .image {
      font-weight: bold;
    }
  }
  .item-skeleton {
    display: grid !important;
    align-items: center !important;
    grid-template-columns: 6.4rem 3fr repeat(3, minmax(90px, 1fr)) 20px !important;
    grid-gap: 1.6rem !important;
    border-radius: 10px !important;
    border-color: transparent !important;
    margin-bottom: 1.6rem;
    .phone-header {
      display: none;
    }
    :global {
      .ant-skeleton-image {
        width: 4.8rem;
        height: 4.8rem;
        svg {
          width: 3.2rem;
          height: 3.2rem;
        }
      }
      .ant-skeleton-paragraph {
        margin: 0;
      }
      .ant-skeleton-title {
        margin-top: 0;
      }
    }

    @include respond-below(sm) {
      grid-template-columns: 1fr !important;
      .phone-header {
        display: block;
        margin: 0;
        color: $text-light;
      }
      .desktop-header {
        display: none !important;
      }
      .skeleton-container {
        display: flex;
        :global {
          .ant-skeleton-content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }
        ul {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }
      .image-container {
        display: flex;
        justify-content: center;
        :global {
          .ant-skeleton-image {
            width: 16rem !important;
            height: 16rem !important;
            svg {
              width: 10rem;
              height: 10rem;
            }
          }
        }
      }
    }
  }
}
