@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.checkout-shipping-methods {
  display: flex;
  flex-direction: column;

  .item {
    display: grid !important;
    grid-template-areas: "title price";
    // "description description";
    grid-template-columns: 1fr 8rem;
    grid-column-gap: 0.8rem;
    align-items: center;
    margin-bottom: 1.6rem;
    text-align: left;

    &.selected {
      border: solid 1px $primary !important;
      background-color: $primary !important;

      * {
        color: #fff !important;
      }
    }

    .title {
      grid-area: title;
      font-size: 1.8rem;
      color: $text-dark;
    }
    .price {
      grid-area: price;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: right;
      color: $primary;
    }
    .description {
      grid-area: description;
      margin-top: 0.8rem;
      font-size: 1.4rem;
      color: $text-light;
      white-space: normal !important;
    }
  }
}
