@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.prescription-customer {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
  margin-bottom: 1.6rem;
  border-radius: 20px;
  color: #fff;
  background: $primary-gradient;

  &:hover {
    .link span {
      margin-left: 0.6rem;
    }
  }

  .info {
    margin-left: 1.6rem;
  }

  .name {
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
  }

  .navigator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .link {
    margin-left: auto;
    font-weight: 500;
    color: #fff;
    span {
      transition: 0.3s ease;
      margin-left: 1.6rem;
    }
  }

  @include respond-below(sm) {
    flex-direction: column;
    padding: 3.2rem;

    .info {
      margin: 1.6rem 0;
      text-align: center;
    }

    .link {
      display: none;
    }
  }
}

.prescription-customer-disabled {
  opacity: 0.7;
  pointer-events: none !important;
  .prescription-customer-link {
    opacity: 0.2;
  }
}
