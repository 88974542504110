@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.prescription-labels-label {
  padding: 0.4rem 0.8rem;
  border-radius: 2px;
  color: #fff;
  font-size: 1.4rem;
  margin-right: 0.8rem;

  .controlled {
    background-color: gray;
  }
  .rSigned {
    background-color: $tertiary-dark;
  }
  .genericDrugs {
    background-color: $primary;
  }
  .waitingList {
    background-color: $secondary-light;
  }
  .prescriptionNotReady {
    background-color: gray;
  }

  @include respond-below(sm) {
    margin-top: 0.8rem;
  }
}
