@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.checkout-summary {
  position: relative;
  z-index: 265;
  :global {
    .ant-collapse-item {
      margin-bottom: 0 !important;
    }
  }

  .spacer {
    height: 1px;
    width: 100%;
    background-color: #eceff5;
    margin: 4.8rem 0;
  }

  .item {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    color: $text-dark;
    margin-bottom: 0.8rem;
    &.tax {
      color: $text-light;
    }
    &.final {
      .value {
        font-size: 2rem;
        color: $primary;
      }
    }
  }
}
