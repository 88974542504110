@import "@styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  padding: .4rem .8rem;
  border-radius: 2px;
  background: $primary;


  span {
    color: #fff;
    font-size: 1.2rem;
  }

  cursor: pointer;

}