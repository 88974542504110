.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;

  h3 {
    margin-bottom: 2rem;
    color: #666;
  }
}
