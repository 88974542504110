@import "@styles/variables.scss";

.order-notes {
  grid-area: notes;
  :global {
    .ant-comment {
      margin-bottom: 2.4rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .ant-comment-inner {
      padding: 0;
    }

    .ant-comment-content-author-name {
      font-size: 1.4rem;
      color: $text;
    }

    .ant-comment-content-author-time {
      font-size: 1.4rem;
      color: $text-light;
    }

    .ant-comment-content-detail {
      font-size: 1.6rem;
    }
  }
}
