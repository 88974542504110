@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.si-status {
  margin: 1.6rem 0;
  .items {
    .item {
      display: grid;
      align-items: center;
      grid-template-areas: "step cost percentage";
      grid-template-columns: 80px 3fr 2fr;
      grid-gap: 1rem;
      padding: 1.6rem 3.2rem;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 1.6rem;
      transition: ease 0.3s;

      &.active {
        background: $primary;
        color: #fff !important;
        * {
          color: #fff;
        }
      }

      &:hover {
        box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
      }

      > div {
        > span {
          display: none;
        }
      }

      .step {
        grid-area: step;
        font-weight: bold;
      }
      .cost {
        grid-area: cost;
      }
      .percentage {
        grid-area: percentage;
      }

      @include respond-below(sm) {
        grid-template-areas:
          "step"
          "cost"
          "percentage";
        grid-template-columns: 1fr;
        > div {
          display: flex;
          justify-content: space-between;
          text-align: right;
          > span {
            display: block;
            font-weight: normal;
            color: $text-light;
            text-align: left;
          }
        }
      }
    }
  }
  :global {
    .ant-table {
      margin: 1.6rem 0;
    }
    .ant-table-row.active {
      background-color: $primary;
      color: #fff;
      > td.ant-table-cell {
        background-color: $primary;
      }
    }
  }
}
