@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.order-address {
  grid-area: address;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;

  .section {
    &:last-of-type {
      margin-left: 3.2rem;
      @include respond-below(sm) {
        margin-left: 0;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 1.6rem;
      color: $text;
    }
  }

  @include respond-below(sm) {
    grid-template-columns: 1fr;
  }
}
