@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.checkout-skeleton {
  position: relative;
  background: #fff !important;
  margin-bottom: 1.6rem;
  :global {
    .ant-skeleton-paragraph {
      margin: 0.8rem 0 !important;
      max-width: 16rem;
      @include respond-below(sm) {
        max-width: 20rem;
      }
      li:last-of-type {
        position: absolute;
        right: 2.4rem;
        top: calc(50% - 2.4rem);
        max-width: 7.2rem;
        @include respond-below(sm) {
          display: none;
        }
      }
    }
  }
}
.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;

  div:nth-child(2) {
    justify-self: end;
  }
  :global {
    .ant-skeleton-button {
      width: 15rem;
    }
  }
}
