@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.checkout-item {
  display: grid;
  align-items: center;
  grid-template-areas: "image name price";
  grid-template-columns: 4.8rem 1fr 8rem;
  grid-gap: 2.4rem;
  margin-bottom: 2.4rem;
  color: $text-dark;

  a {
    color: $text-dark;
    &:hover {
      color: $primary;
    }
  }

  .image {
    width: 4.8rem;
    height: 4.8rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .price {
    text-align: right;
  }
}
