@mixin cssVars($css_variables, $prefix: pf) {
  :root {
    @each $name, $value in $css_variables {
      --#{$prefix}--#{$name}: #{$value};
    }
  }
}

// root variables used in kaktus-ecommerce package
@include cssVars(
  (
    primary: $primary,
  ),
  "ke--color"
);
