@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.prescription-skeleton {
  .top-row {
    :global {
      .ant-skeleton-paragraph {
        display: flex;
        justify-content: space-between;
        li {
          margin: 0 !important;
          height: 1.5rem;
          max-width: 12rem;
          &:last-of-type {
            max-width: 8rem;
          }
        }
      }
    }
  }
  .title {
    padding-top: 1.6rem;
    padding-bottom: 0.8rem;
    :global {
      .ant-skeleton-title {
        height: 2.4rem;
        margin: 0 !important;
        margin-bottom: 0.8rem !important;
        @include respond-below(sm) {
          width: 24rem !important;
          margin-bottom: 1.6rem !important;
        }
      }
      .ant-skeleton-paragraph {
        display: none;
      }
    }
  }
  .center-row {
    :global {
      .ant-skeleton-paragraph {
        display: flex;
        justify-content: space-between;
        @include respond-below(sm) {
          flex-direction: column;
        }
        li {
          margin: 0 !important;
          max-width: 8rem;
          @include respond-below(sm) {
            margin-bottom: 1.2rem !important;
          }
          &:last-of-type {
            max-width: 14.4rem;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
  .bottom-row {
    display: flex;
    justify-content: space-between;
    padding-top: 3.2rem;
    @include respond-below(sm) {
      flex-direction: column;
      :global {
        .ant-skeleton {
          margin-top: 1.6rem;
        }
      }
    }
    :global {
      .ant-skeleton-button {
        height: 4.8rem;
        width: 15.2rem;
        border-radius: 24px;
        @include respond-below(sm) {
          width: 100%;
        }
      }
    }
  }
}
