@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.otc-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.6rem;

  @include respond-below(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond-below(xs) {
    grid-template-columns: 1fr;
  }
}