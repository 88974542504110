@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.checkout-content {
  position: relative;
  display: grid;
  grid-template-areas: "steps order";
  grid-template-columns: 1fr 47.6rem;
  grid-gap: 4.4rem;
  @include respond-below(lg) {
    grid-template-areas:
      "order"
      "steps";
    grid-template-columns: 1fr;
  }

  .steps {
    grid-area: steps;
    .content {
      padding: 3.2rem 0;
    }

    // Removing hover effects from steps
    :global {
      .ant-steps {
        pointer-events: none;
      }
    }
  }
  .order {
    grid-area: order;
    padding-left: 2rem;
    @include respond-below(lg) {
      
      position: sticky;
      top: $header-height;
      
      background-color: #fafafa;
      margin: 0rem -2rem;
      margin-bottom: 1.6rem;
      padding: 2rem;

      .medio-collapsible {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        :global {
          .ant-collapse-item-active .ant-collapse-header {
            margin-bottom: 4.8rem;
          }
        }
      }
      .medio-content-box.rounded {
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    .cart-items {
      position: relative;
      z-index: 155;
    }
    &:before {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 260;
      background-color: #fafafa;
      width: calc(
        (100vw - 1200px) / 2 + 50rem
      ); // The margin on the right side of the screen + 500px of the content
      @include respond-below(lg) {
        display: none;
      }
    }
  }
}
