@import "@styles/variables.scss";

.order-details-general {
  grid-area: general;

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    color: $text-light;

    &-value {
      font-weight: bold;
      color: $text;
      text-align: right;
    }

    &-key {
      
    }
    &.status {
      font-size: 1.8rem;
      margin-bottom: 1.6rem;
    }
    &.status &.value {
      color: $primary;
    }
  }
}
