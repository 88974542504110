@import "@styles/responsive-helpers.scss";

.order-details-content {
  display: grid;
  grid-template-areas:
    "general summary"
    "address address"
    "notes notes";
  grid-gap: 2rem;

  @include respond-below(sm) {
    grid-template-areas:
      "general"
      "summary"
      "address"
      "notes";
  }
}
