@import "@styles/responsive-helpers.scss";

.action {
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
  
  button:last-of-type {
    margin-left: 0.8rem;
  }
  
  @include respond-below("xs") {
    flex-direction: column-reverse;
    align-items: center;
    button {
      max-width: 80%;
      min-width: 20rem;
      
      &:first-of-type {
        margin-left: 0; 
        margin-top: 0.8rem;
      }
    }
  }


}
