@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.si-status-header {
  display: grid;
  align-items: center;
  grid-template-areas: "step cost percentage";
  grid-template-columns: 80px 3fr 2fr;
  grid-gap: 1rem;
  padding: 1.6rem 3.2rem;
  @include respond-below(sm) {
    display: none;
  }
}
