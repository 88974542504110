@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.cart-items {
  .title {
    display: none;
    margin: 0;
    padding-top: 3.6rem;
    padding-bottom: 1.6rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: $text;
    @include respond-below(sm) {
      display: block;
    }
  }
  .header {
    display: grid;
    align-items: center;
    grid-template-areas: "image name fullprice quantity price action";
    grid-template-columns: 6.4rem 3fr repeat(3, minmax(90px, 1fr)) 20px;
    grid-gap: 1.6rem;
    padding: 1.6rem 3.2rem;
    @include respond-below(sm) {
      display: none;
    }
    .image {
      font-weight: bold;
    }
  }
}
