@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.order {
  display: grid;
  align-items: center;
  grid-template-areas: "id date shipping-method total status";
  grid-template-columns:
    minmax(50px, 1fr) minmax(100px, 1fr) 1fr minmax(80px, 1fr)
    105px;
  grid-gap: 1rem;
  padding: 2.4rem 3.2rem;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 1.6rem;
  transition: ease 0.3s;

  &:hover {
    box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .label {
    display: none;
  }

  .id {
    grid-area: id;
    font-weight: 700;
    color: $primary;
  }

  .date {
    grid-area: date;
  }

  .shipping-method {
    grid-area: shipping-method;
  }

  .total {
    grid-area: total;
  }

  .status {
    grid-area: status;
    .status-tag {
      display: flex;
    }
  }

  @include respond-below(sm) {
    grid-template-areas:
      "id"
      "date"
      "shipping-method"
      "total"
      "status";
    grid-template-columns: 1fr;
    text-align: right;
    .name {
      .description {
        display: none;
      }
    }
    .description {
      grid-area: description;
    }
    .label {
      display: block;
      font-weight: normal;
      color: $text-light;
    }
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
}
