.group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.6rem;
}

.simple {
  width: 100%;
  margin-bottom: 1.6rem;
}