@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.checkout-payment-methods {
  :global {
    .ant-collapse-item-active {
      border: solid 1px $primary !important;
    }
  }

  .no-margin {
    :global {
      .ant-collapse-header {
        margin-bottom: 0 !important;
      }
    }
  }

  .no-options {
    > div {
      margin-bottom: 0 !important;
      padding-right: 0 !important;
    }

    :global {
      .anticon.anticon-right.ant-collapse-arrow {
        display: none !important;
      }
    }
  }
}
