@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.order-list-skeleton {
  display: flex !important;
  :global {
    .ant-skeleton-paragraph {
      display: grid;
      align-items: center;
      grid-template-areas: "id date shipping-method total status";
      grid-template-columns:
        minmax(50px, 1fr) minmax(100px, 1fr) 1fr minmax(80px, 1fr)
        105px;
      grid-gap: 1rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      margin: 0;
      li {
        margin: 0 !important;
        &:nth-of-type(1) {
          grid-area: id;
          max-width: 7.2rem;
        }
        &:nth-of-type(2) {
          grid-area: date;
          max-width: 11.2rem;
        }
        &:nth-of-type(3) {
          grid-area: shipping-method;
          max-width: 12rem;
        }
        &:nth-of-type(4) {
          grid-area: total;
          max-width: 7.2rem;
        }
        &:nth-of-type(5) {
          grid-area: status;
          width: 100% !important;
        }
      }
    }
  }

  .mobile-only {
    display: none;
  }

  @include respond-below(sm) {
    justify-content: space-between;
    :global {
      .ant-skeleton {
        width: calc(50% - 1.6rem);
      }
      .ant-skeleton-paragraph {
        grid-template-areas:
          "id"
          "date"
          "shipping-method"
          "total"
          "status";
        grid-template-columns: 1fr;
        li {
          margin-bottom: 0.8rem !important;
          &:last-of-type {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    li {
      &:first-of-type {
        max-width: 5.2rem;
      }
      &:nth-of-type(2) {
        max-width: 8rem;
      }
      &:nth-of-type(3) {
        max-width: 12rem;
      }
      &:nth-of-type(4) {
        max-width: 5.4rem;
      }
      &:nth-of-type(5) {
        max-width: 4rem;
      }
    }
    .mobile-only {
      display: flex;
      :global {
        .ant-skeleton-paragraph {
          justify-items: end;
        }
      }
      li {
        &:first-of-type {
          max-width: 3.2rem;
        }
        &:nth-of-type(2) {
          max-width: 11.2rem;
        }
        &:nth-of-type(3) {
          max-width: 5.4rem;
        }
        &:nth-of-type(4) {
          max-width: 6.4rem;
        }
        &:nth-of-type(5) {
          max-width: 4.8rem;
        }
      }
    }
  }
}
