@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.cart-totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 6.4rem;
  margin-bottom: 3.2rem;

  

  .tax,
  .total {
    display: flex;
    justify-content: space-between;
    width: 30rem;
    font-size: 2rem;
    max-width: 100%;
    @include respond-below(sm) {
      font-size: 1.8rem;
      width: 100%;
    }
  }

  .value {
    font-weight: 700;
    color: $primary;

    :global {

      .ant-skeleton-paragraph {
        margin: 0 !important;
        width: 20rem;
        height: 1.6rem;
        li {
          margin: 0 !important;
          margin-left: auto !important;
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 2rem;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  
}
