@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.prescription {
  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  border: 1px solid $border-light;
  border-radius: 20px;
  background-color: #fff;
  transition: ease 0.3s;
  margin-bottom: 32px;

  // @media only screen and (max-width: 768px) {
  //   padding: 20px;
  // }

  &:hover {
    box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .top-row {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 15px;
    color: $text-light;
    p {
      margin: 0;
    }
    span {
      color: $primary;
    }
    i {
      margin-right: 8px;
      color: $primary;
    }
  }

  .drug-name {
    padding-top: 1.6rem;
    padding-bottom: 0.8rem;
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 700;
    color: $text;
  }

  .drug-type {
    font-size: 1.6rem;
    line-height: 2rem;
    color: $text-light;
    display: flex;
    justify-content: space-between;

    .packages {
      display: flex;
      align-items: center;

      .input {
        width: 6.4rem;
        margin-left: 2rem;
        margin-right: 2rem;
        padding: 1rem !important;
        text-align: center;
      }
      span {
        font-weight: 900;
        color: $text;
        margin-left: 0.8rem;
      }
    }

    @include respond-below(sm) {
      margin-top: 0.8rem;
      flex-direction: column;
      div {
        margin-top: 0.8rem;
      }
    }
  }
}
