@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.generics-modal {
  .list {
    max-height: calc(100vh - 52.5rem);
    overflow-y: auto;
    @include respond-below(sm) {
      max-height: calc(100vh - 48.5rem);
    }
  }

  :global {
    .ant-modal-content {
      border-radius: 20px;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-body,
    .ant-modal-footer {
      padding: 0 3.2rem;
      background-color: $background;
      border: none;
    }

    .ant-modal-body {
      padding-top: 6.4rem;
      padding-bottom: 3.2rem;
      border-radius: 20px 20px 0 0;
      @include respond-below(sm) {
        padding-top: 3.2rem;
        padding-bottom: 0;
      }
    }

    .ant-modal-footer {
      padding-bottom: 6.4rem;
      border-radius: 0 0 20px 20px;
      @include respond-below(sm) {
        padding-bottom: 3.2rem;
      }
    }
  }

  .title {
    font-size: 1.8rem;
    color: $text-light;
    font-weight: 500;
    margin-bottom: 1rem;
    @include respond-below(sm) {
      font-size: 1.6rem;
    }
    & ~ & {
      margin-top: 1.6rem;
    }
  }

  .footer {
    display: flex;
    button {
      flex-grow: 1;
      &:last-of-type {
        margin-left: 1.6rem;
      }
    }

    @include respond-below(sm) {
      flex-direction: column;
      button {
        margin-top: 1.6rem;
      }
    }
  }
}
