@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.user-update-form {
  display: grid;
  grid-template-areas:
    "heading heading"
    "email phone"
    "first last"
    "address1 address1"
    "city zip"
    "company company"
    "shipmentOther shipmentOther";
  grid-column-gap: 2.4rem;
  grid-row-gap: 1.6rem;
  margin-bottom: 0 !important;

  :global {
    .ant-form-item-optional {
      visibility: hidden;
      position: relative;
      &::after {
        visibility: visible;
        content: "(Valkvætt)";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @include respond-below(sm) {
    grid-template-areas:
      "heading"
      "email"
      "phone"
      "first"
      "last"
      "address1"
      "city"
      "zip"
      "company"
      "shipmentOther";
  }

  .heading {
    grid-area: heading;
  }
  .first-name {
    grid-area: first;
  }
  .last-name {
    grid-area: last;
  }
  .address-1 {
    grid-area: address1;
  }
  .city {
    grid-area: city;
  }
  .postcode {
    grid-area: zip;
  }
  .email {
    grid-area: email;
  }
  .phone {
    grid-area: phone;
  }
  .company {
    grid-area: company;
  }
  .shipment-other {
    grid-area: shipmentOther;
  }
}

.user-shipping {
  display: grid;
  grid-template-areas:
    "heading heading"
    "address1 address1"
    "city zip"
    "company company";
  grid-column-gap: 2.4rem;
  grid-row-gap: 1.6rem;
  margin-bottom: 0 !important;

  :global {
    .ant-form-item-optional {
      visibility: hidden;
      position: relative;
      &::after {
        visibility: visible;
        content: "(Valkvætt)";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  @include respond-below(sm) {
    grid-template-areas:
      "heading"
      "address1"
      "city"
      "zip"
      "company";
  }

  .heading {
    grid-area: heading;
  }
  .address-1 {
    grid-area: address1;
  }
  .city {
    grid-area: city;
  }
  .postcode {
    grid-area: zip;
  }
  .company {
    grid-area: company;
  }
}

.save {
  margin-top: 2.4rem;
  margin-left: auto;
}
