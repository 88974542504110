@import "@styles/variables.scss";

.container {
  display: flex;
  margin-bottom: 2.4rem;
  margin-right: 4.2rem;
  svg {
    width: 2.4rem;
    height: 2.4rem;

  }

  .label {
    margin-left: 1.4rem;
    
    
    font-weight: 700;
    font-size: 1.6rem;
    color: $text;
  }
}